import React from "react"
import { graphql } from "gatsby"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { LoginForm } from "components/login/LoginForm"
import { Image } from "components/image/Image"
import type { Banner } from "./HeroBanner-types"
import "./hero.module.scss"

interface LoginBannerProps {
  banner: Banner
}

export const LoginBanner = ({ banner }: LoginBannerProps) => {
  const { background_image, heading, sub_heading_rich } = banner

  const { imageBannerColorOverlay } = useColorAreaSettings()

  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""

  return (
    <section className={`fr-hero fr-hero--500 ${overrideBackground}`}>
      {background_image && (
        <Image
          alt={background_image.alternativeText || ""}
          className="fr-hero__background-image"
          image={background_image}
        />
      )}
      <div className="login-banner-content fr-container">
        <LoginForm
          heading={heading}
          subHeading={sub_heading_rich?.data?.sub_heading_rich}
        />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment StrapiLoginBannerFragment on STRAPI__COMPONENT_BANNERS_HERO_BANNER {
    id
    heading
    sub_heading_rich {
      data {
        sub_heading_rich
      }
    }
    background_image {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(
            avifOptions: { lossless: true, speed: 10, quality: 50 }
            webpOptions: { quality: 60 }
            formats: [AUTO, AVIF, WEBP]
            placeholder: BLURRED
            breakpoints: [1024, 1366, 1920, 2560]
          )
        }
        publicURL
      }
    }
  }
`
