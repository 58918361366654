import React from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { Layout } from "components/layout"
import { SEO } from "components/seo/seo"
import { LoginBanner } from "components/hero/LoginBanner"
import { GlobalFooter } from "components/global-footer/GlobalFooter"
import { ContentBlocks } from "components/content-blocks/ContentBlocks"
import { EMPTY_PAGE } from "assets/helper/EmptyPage"

type PageContext = PageProps["pageContext"] & {
  slug: string
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  contentUrlPathCodex: Record<string, string>
  linkedPagesUrlPathCodex: Record<string, string>
}

interface LoginPageProps extends PageProps {
  pageContext: PageContext
}

const LoginPage: React.FC<LoginPageProps> = ({ location, pageContext }) => {
  const { contentUrlPathCodex, slug, linkedPagesUrlPathCodex } = pageContext
  const { pathname, hash } = location

  const data = useStaticQuery(getLoginData)
  const [page] = data.allStrapiPage.nodes
  const { hero, global_footer, content, meta_data, alternating_content } =
    page ?? EMPTY_PAGE

  return (
    <Layout
      contentUrlPathCodex={contentUrlPathCodex}
      location={slug}
      pathName={pathname}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
    >
      <SEO
        title={meta_data?.meta_title}
        description={meta_data?.meta_description}
        noIndex={true}
      />
      {hero && <LoginBanner banner={hero} />}

      {content?.length > 0 && (
        <div className="login-content">
          <ContentBlocks
            strapiContent={content}
            hasAlternatingContentBlocks={alternating_content}
          />
        </div>
      )}

      {global_footer?.content?.length > 0 && (
        <GlobalFooter content={global_footer?.content} />
      )}
    </Layout>
  )
}

const getLoginData = graphql`
  query getLoginData {
    allStrapiPage(filter: { slug: { eq: "login" } }) {
      nodes {
        meta_data {
          meta_title
          meta_description
          canonical_link
          rich_data_image {
            localFile {
              publicURL
            }
          }
        }
        hero {
          ...StrapiLoginBannerFragment
        }
        content {
          ...StrapiContentBlockFragment
          ...StrapiCouponBannerFragment
          ...StrapiCTABannerFragment
          ...StrapiFaqGroupBannerFragment
          ...StrapiFormFragment
          ...StrapiHighlightsGroupFragment
          ...StrapiHtmlEmbedFragment
          ...StrapiLogoSliderFragment
          ...StrapiMultiContentBlocksFragment
          ...StrapiFormFragment
          ...StrapiGalleryFragment
          ...StrapiNavigationTilesFragment
          ...StrapiServiceAreaGroupFragment
          ...StrapiServiceComparisonFragment
          ...StrapiServiceFragment
          ...StrapiTabbedContentBannerFragment
        }
        global_footer {
          ...StrapiGlobalFooterFragment
        }
        alternating_content
      }
    }
  }
`

export default LoginPage
